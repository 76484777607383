'use client';

import { init } from './init';
import { useEffect } from 'react';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';
import { useUserSession } from '../../store/user/hooks';
import * as env from '../env';
import Bugsnag from '@bugsnag/js';

export function BugsnagClient() {
  const session = useUserSession();
  const { user } = session.data || {};

  useEffect(() => {
    init({
      plugins: [new BugsnagPluginReact()],
    });

    BugsnagPerformance.start({
      apiKey: env.bugsnagAPIKey as string,
    });
  }, []);

  useEffect(() => {
    if (user?.manager_id) {
      Bugsnag.setUser(
        user?.manager_id,
        user?.manager_email,
        `${user?.first_name} ${user?.last_name}`
      );
    }
  }, [user]);

  return null;
}
