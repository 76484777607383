'use client';

import { UserbackProvider } from '@userback/react';
import React from 'react';
import * as env from '../lib/env';
import { useUserSession } from '../store/user/hooks';

export function UserBack({ children }: React.PropsWithChildren) {
  const session = useUserSession();
  const { user } = session.data || {};

  const options = {
    id: user?.manager_id?.toString() || '',
    name: user?.first_name || '',
    email: user?.manager_email || '',
    is_live: env.isProduction,
    custom_data: {
      environment: env.getEnv(),
    },
    widgetSettings: {
      form_settings: {
        general: {
          name_field: true,
        },
        bug: {
          name_field: true,
        },
        feature_request: {
          name_field: true,
        },
      },
    },
  };

  return (
    <UserbackProvider
      token={env.userbackToken as string}
      options={options}
      delayInit={false}
    >
      {children}
    </UserbackProvider>
  );
}
