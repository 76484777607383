'use client';

import { useEffect } from 'react';
import { FullStory as FS, init } from '@fullstory/browser';
import * as env from '../lib/env';
import { useUserSession } from '../store/user/hooks';

export function FullStory() {
  const session = useUserSession();
  const { user } = session.data || {};
  const orgId = 'CKVHX';

  useEffect(() => {
    init({
      orgId: orgId,
      devMode: !env.isProduction,
    });
  }, []);

  useEffect(() => {
    if (user?.manager_id) {
      FS('setIdentity', {
        uid: user.manager_id.toString(),
        properties: {
          displayName: `${user.first_name || ''} ${
            user.last_name || ''
          }`.trim(),
          email: user.manager_email || undefined,
        },
      });
    } else {
      FS('setIdentity', false);
    }
  }, [user]);

  return null;
}
