import { NextRouter } from 'next/router';
import { CookieKeys } from './constants';

const test = 'test';
const staging = 'staging';
const production = 'production';
const development = 'development';
const local = 'local';

type EnvType =
  | typeof test
  | typeof staging
  | typeof production
  | typeof development
  | typeof local;

const allowedCookiesDomains =
  process.env.NEXT_PUBLIC_ALLOWED_COOKIE_DOMAINS || CookieKeys.Domains;
const bugsnagAPIKey = process.env.NEXT_PUBLIC_BUGSNAG_API_KEY || 'fake-key';
const fullStoryOrgId = process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID;
const userbackToken = process.env.NEXT_PUBLIC_USER_BACK_TOKEN;
const apiHost = process.env.NEXT_PUBLIC_API_HOST;
const rgAPIHost = process.env.NEXT_PUBLIC_RG_API_HOST;
const environment = (process.env.NEXT_PUBLIC_APP_ENV || local) as EnvType;
const chatShareUrlPrefix = process.env.NEXT_PUBLIC_CHAT_SHARE_URL_PREFIX;
const managerAppUrl =
  process.env.NEXT_PUBLIC_MANAGER_APP_URL ?? 'https://manager.rentgrata.com';
const envs = {
  [test]: test,
  [staging]: staging,
  [production]: production,
  [development]: development,
  [local]: local,
};

const isTesting: boolean = envs.test === environment;
const isStaging: boolean = envs.staging === environment;
const isProduction: boolean = envs.production === environment;
const isDevelopment: boolean = envs.development === environment;
const isLocal: boolean = envs.local === environment;

const isSSR = typeof window === 'undefined';

const getEnv = (): string => envs[environment];

const getHostURL = (): string => {
  if (isSSR) return '';

  return `${window.location.protocol}//${window.location.host}`;
};

const getCallbackUrl = (router: NextRouter, path?: string): string => {
  return (
    (router.query?.callbackUrl as string) || `${getHostURL()}/${path || '/'}`
  );
};

export {
  apiHost,
  rgAPIHost,
  isTesting,
  isStaging,
  isProduction,
  isDevelopment,
  isLocal,
  isSSR,
  getEnv,
  getHostURL,
  getCallbackUrl,
  userbackToken,
  fullStoryOrgId,
  bugsnagAPIKey,
  chatShareUrlPrefix,
  managerAppUrl,
  allowedCookiesDomains,
};
