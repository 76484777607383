import Bugsnag from '@bugsnag/js';
import * as env from '../env';
import type { User } from '../../store/user/types';

type Props = {
  user?: User | null;
  plugins: Array<any>;
};

export const init = (props: Props) => {
  return Bugsnag.start({
    apiKey: env.bugsnagAPIKey as string,
    plugins: props.plugins,
    releaseStage: env.getEnv(),
    onError: function (event) {
      event.addMetadata('environment', {
        env: env.getEnv(),
      });
    },
  });
};
