'use client';
import { useServerInsertedHTML } from 'next/navigation';
import {
  Lora as HeadingFont,
  Plus_Jakarta_Sans as SansFont,
} from 'next/font/google';

const fontFamilySans = SansFont({
  subsets: ['latin'],
  variable: '--font-family-sans',
  fallback: ['system-ui', 'Helvetica Neue', 'Helvetica', 'Arial'],
  preload: true,
  weight: ['300', '400', '500', '600', '700', '800'],
});

// replace with the heading font
// by default, it will use the sans font
const heading = HeadingFont({
  subsets: ['latin'],
  variable: '--font-family-heading',
  fallback: ['Georgia', 'serif'],
  preload: true,
  weight: ['400', '500', '600', '700'],
});

function Fonts() {
  useServerInsertedHTML(() => {
    return (
      <style
        key={'fonts'}
        dangerouslySetInnerHTML={{
          __html: `
          :root {
             --font-family-sans: ${fontFamilySans.style.fontFamily}, 
              '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Ubuntu',
              'sans-serif';

            --font-family-heading: ${heading.style.fontFamily}, '-apple-system', 'BlinkMacSystemFont';
          }
        `,
        }}
      />
    );
  });

  return null;
}

export default Fonts;
